/* import
---------------------------------------------------------- */
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

/* Webフォント
---------------------------------------------------------- */
const webfont = () => {

	const fontActive = new Event("fontActive");

	window.WebFontConfig = {
		google: {
			families: [
				'Noto+Sans+JP:400,500,700,900',
				'Noto+Serif+JP:400,500',
				'Inter:wght@400;500;600',
				// 'GFS+Didot:400',
			],
		},
		active: () => {
			sessionStorage.fonts = true;
			window.dispatchEvent(fontActive);
		},
	};
	(() => {
		let wf = document.createElement('script');
		wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
		wf.type = 'text/javascript';
		wf.async = 'true';
		let s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(wf, s);
	})();
};

/* グローバルナビ
---------------------------------------------------------- */
const gnavAction = () => {
	const body = document.body;
	const gnavOpenClass = 'is-nav-opened';
	const gnavCloseClass = 'is-nav-closed';
	const gnav = document.getElementById('gnav');

	window.addEventListener('touchmove', (e) => {
		if (body.classList.contains(gnavOpenClass) && e.target === gnav) {
			e.preventDefault();
		} else {
			e.stopPropagation();
		}
	}, { passive: false });

	if (body.classList.contains(gnavOpenClass)) {
		body.classList.remove(gnavOpenClass);
		body.classList.add(gnavCloseClass);
	} else {
		body.classList.remove(gnavCloseClass);
		body.classList.add(gnavOpenClass);
	}
};

const gnavToggle = () => {
	const gnavToggle = document.querySelectorAll('.js-gnav-toggle');
	const overlay = document.getElementById('overlay');

	// ハンバーガーメニュークリック
	if (gnavToggle !== null) {
		Array.prototype.forEach.call(gnavToggle, (el) => {
			el.addEventListener('click', (e) => {
				gnavAction(e);
			});
		});
	}

	// 背景クリック
	if (overlay !== null) {
		overlay.addEventListener('click', (e) => {
			gnavAction(e);
		});
	}
};

const disableScroll = (el) => {

	const body = document.body;
	const className = 'is-scroll-disabled';

	// el.scrollTop = 1;

	window.addEventListener('touchmove', (event) =>  {
		if( document.body.classList.contains(className) && event.target === el ){
			event.preventDefault();
		} else {
			event.stopPropagation();
		}
	},{ passive: false });

	// if( body.classList.contains(className) ){
	// 	body.classList.remove(className);
	// } else {
	// 	body.classList.add(className);
	// }

};

/* 要素のフェードイン
---------------------------------------------------------- */
const inView = () => {
	const items = document.querySelectorAll('.js-inview');
	const isInview = 'is-inview';

	if (items.length < 1) return false;

	const cb = (entries, observer) => {
		entries.forEach((entry) => {

			const toggle = entry.target.dataset.inviewToggle;

			if (entry.isIntersecting) {

				entry.target.classList.add(isInview);

			} else {

				if ( toggle ) {
					entry.target.classList.remove(isInview)
				}

			}

		});
	};

	const options = {
		root: null,
		rootMargin: '0px 0px 0px 0px',
		threshold: [0.1]
	};

	const io = new IntersectionObserver(cb, options);

	items.forEach((item) => {
		io.observe(item);
	});

	const transitionLast = document.querySelectorAll('.transition-last');
	transitionLast.forEach(el => {
		const parent = el.parentElement;
		const next = parent.dataset.next;
		const nextEl = document.querySelector(`.${next}`);

		el.addEventListener('transitionend', () => {
			el.parentElement.classList.add('is-transitionend');

			if ( parent.classList.contains('is-inview') ) {
				if ( !next || next === 'hero-cost' && !isPc() ) return false;
				nextEl.classList.add('is-inview');
			}
		});
	});
};

/* splitText
---------------------------------------------------------- */
const splitText = () => {

	const targets = document.querySelectorAll('.js-split-text');
	const spanClass = 'split-text';

	const spanWrapText = (target, nodes) => {
		let spanWrapText = ""
		nodes.forEach((node, i) => {
			if (node.nodeType == 3) { // テキストの場合
				const text = node.textContent.replace(/\r?\n/g, ''); // テキストから改行コード削除
				// spanで囲んで連結
				spanWrapText = spanWrapText + text.split('').reduce((acc, v) => {
					return acc + `<span class="split-text">${v}</span>`
				}, "");
			} else { //テキスト以外
				// <br>などテキスト以外の要素をそのまま連結
				spanWrapText = spanWrapText + node.outerHTML
			}
		});
		target.innerHTML = spanWrapText;
	}

	targets.forEach( target => {
		const nodes = [...target.childNodes];
		const inners = target.querySelectorAll(spanClass);

		if ( inners ) {
			inners.forEach( inner => {
				const childNodes = [...inner.childNodes];
				spanWrapText(inner, childNodes);
			});
		}

		spanWrapText(target, nodes);

		const items = target.querySelectorAll(`.${spanClass}`);
		const length = items.length;

		items.forEach( (item, i) => {
			const delay = ( target.dataset.delay ) ? i * target.dataset.delay : i * 0.1 ;
			item.style.transitionDelay = `${delay}s`;

			if ( i+1 == length ) {
				item.classList.add(`${spanClass}--last`);
			}
		});

		const last = target.querySelectorAll(`.${spanClass}--last`);
		last.forEach( el => {
			el.addEventListener('transitionend', () => {
				if ( target.classList.contains('is-inview') ) {
					target.classList.add('is-transitionend');
				}
			});
		});

	});

}

/* アコーディオン
---------------------------------------------------------- */
const accordion = () => {

	const details = document.querySelectorAll(".js-details");
	const RUNNING_VALUE = "running";
	const IS_OPENED_CLASS = "is-opened";

	details.forEach((el) => {
		const summary = el.querySelector(".js-summary");
		const content = el.querySelector(".js-content");

		summary.addEventListener("click", (event) => {
			event.preventDefault();

			if (el.dataset.animStatus === RUNNING_VALUE) {
				return;
			}

			if (el.open) {

				el.classList.toggle(IS_OPENED_CLASS);

				const closingAnim = content.animate(closingAnimKeyframes(content), animTiming);
				el.dataset.animStatus = RUNNING_VALUE;

				closingAnim.onfinish = () => {
					el.removeAttribute("open");
					el.dataset.animStatus = "";
				};

			} else {

				el.setAttribute("open", "true");
				el.classList.toggle(IS_OPENED_CLASS);
				const openingAnim = content.animate(openingAnimKeyframes(content), animTiming);
				el.dataset.animStatus = RUNNING_VALUE;

				openingAnim.onfinish = () => {
					el.dataset.animStatus = "";
				};
			}
		});
	});

	const animTiming = {
		duration: 400,
		easing: "ease-out"
	};

	const closingAnimKeyframes = (content) => [
		{
			height: content.offsetHeight + 'px',
			// opacity: 1,
		}, {
			height: 0,
			// opacity: 0,
		}
	];

	const openingAnimKeyframes = (content) => [
		{
			height: 0,
			// opacity: 0,
		}, {
			height: content.offsetHeight + 'px',
			// opacity: 1,
		}
	];
}

//* slider
//---------------------------------------------------------- */
const slider = () => {

	Swiper.use([Navigation, Pagination, Scrollbar, Autoplay]);

	// const introductionSwiper = new Swiper('.introduction-slider', {
	// 	slidesPerView: 1,
	// 	spaceBetween: 20,
	// 	navigation: {
	// 		nextEl: '.slider-button-next',
	// 		prevEl: '.slider-button-prev',
	// 	},
	// 	scrollbar: {
	// 		el: '.swiper-scrollbar',
	// 		hide: false,
	// 		draggable: true,
	// 		dragSize: '85px',
	// 	},
	// 	breakpoints: {
	// 		768: {
	// 			slidesPerView: 1.81,
	// 			spaceBetween: 30,
	// 		}
	// 	}
	// });
	
	const heroSwiper = new Swiper('.hero-01-swiper', {
		loop: true,
		speed: 1000,
		autoplay: {
		    delay: 4000,
		    disableOnInteraction: false,
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		}
	});
	
	const worksSingleSwiper = new Swiper('.works_single-swiper', {
		loop: true,
		speed: 1000,
		autoplay: {
		    delay: 4000,
		    disableOnInteraction: false,
		},
		pagination: {
			el: '.works_single-pagination.swiper-pagination',
			clickable: true,
		}
	});

	const worksSwiper = new Swiper('.works-swiper', {
		slidesPerView: 1.2,
		spaceBetween: 20,
		scrollbar: {
			el: '.swiper-scrollbar',
			hide: false,
			draggable: true,
			dragSize: '85px',
		},
		breakpoints: {
			768: {
				slidesPerView: 1.81,
				spaceBetween: 30,
				navigation: {
					nextEl: '.slider-button-next',
					prevEl: '.slider-button-prev',
				},
			}
		}
	});
	
	const serviceSwiper = new Swiper('.service-swiper', {
		//effect: 'fade',
		loop: true,
		speed: 1000,
		autoplay: {
		    delay: 5000,
		    disableOnInteraction: false,
		},
		pagination: {
			el: '.service-swiper-pagination',
			clickable: true,
		}
	});
	
	
	

	// new Splide( '.introduction-slider', {
	// 	type   : 'loop',
	// 	autoWidth: true,
	// 	padding: '12.5vw',
	// }).mount();

	// new Splide( '.works-slider', {
	// 	type   : 'loop',
	// 	autoWidth: true,
	// }).mount();

}

/* setFillHeight
---------------------------------------------------------- */
const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let vw = window.innerWidth;

window.addEventListener('resize', () => {
  if (vw === window.innerWidth) {
  // 画面の横幅にサイズ変動がないので処理を終える
    return;
  }

  // 画面の横幅のサイズ変動があった時のみ高さを再計算する
  vw = window.innerWidth;
  setFillHeight();
});

/* resizeEnd
---------------------------------------------------------- */
const event = new CustomEvent('resizeend');
const currentWidth = window.innerWidth;
let timeoutID = 0;
let delay = 500;

window.addEventListener("resize", () => {

	if ( currentWidth === window.innerWidth ) return false;

	clearTimeout(timeoutID);
	timeoutID = setTimeout( () => {
		window.dispatchEvent(event);
	}, delay);

}, false);

/* mediaQuery
---------------------------------------------------------- */
const isPc = () => {

	const mq = ( window.matchMedia( "(min-width: 768px)" ).matches ) ? true : false;
	return mq;

}

/* gsapAnimation
---------------------------------------------------------- */
const gsapAnimation = () => {
	
	
	
	let mm = gsap.matchMedia();
	
	mm.add("(min-width: 767px)", () => {
		ScrollTrigger.create({
		    trigger: '#hero-01',
			start: 'bottom top',
			endTrigger: "html",
			end: "bottom top",
			//end: 'top top',
			//scroller:"#js-scroll",
			//markers: true,
			//toggleClass: "is-active",
			toggleClass: { targets: '#header_top', className: 'header_top_active' },	   
		});
	    // ここに1025px以上のときのコードを書きます
	});
	
	mm.add("(min-width: 1025px)", () => {
	
		gsap.fromTo(
		  ".pall02", // アニメーションさせる要素
		  {
		    yPercent: 50, // 下に要素の幅の1倍分移動
		  },
		  {
		    yPercent: -50, // 上に要素の幅の1倍分移動
		    ease: "none", // イージングなし
		    scrollTrigger: {
		      trigger: ".js-trigger-top", // アニメーションのトリガー要素
		      start: "top bottom", // アニメーション開始位置
		      end: "bottom top", // アニメーション終了位置
		      scrub: true, // 動作を遅くさせない
		    },
		  }
		);
		
		gsap.fromTo(
		  ".pall03", // アニメーションさせる要素
		  {
		    yPercent: 0, // 下に要素の幅の1倍分移動
		  },
		  {
		    yPercent: 50, // 上に要素の幅の1倍分移動
		    ease: "none", // イージングなし
		    scrollTrigger: {
		      trigger: ".js-trigger-top", // アニメーションのトリガー要素
		      start: "top bottom", // アニメーション開始位置
		      end: "bottom top", // アニメーション終了位置
		      scrub: true, // 動作を遅くさせない
		    },
		  }
		);
		
		gsap.fromTo(
		  ".pall04", // アニメーションさせる要素
		  {
		    yPercent: 50, // 下に要素の幅の1倍分移動
		  },
		  {
		    yPercent: 0, // 上に要素の幅の1倍分移動
		    ease: "none", // イージングなし
		    scrollTrigger: {
		      trigger: ".js-trigger-top", // アニメーションのトリガー要素
		      start: "top bottom", // アニメーション開始位置
		      end: "bottom top", // アニメーション終了位置
		      scrub: true, // 動作を遅くさせない
		    },
		  }
		);
		
		gsap.fromTo(
		  ".pall05", // アニメーションさせる要素
		  {
		    yPercent: 100, // 下に要素の幅の1倍分移動
		  },
		  {
		    yPercent: -100, // 上に要素の幅の1倍分移動
		    ease: "none", // イージングなし
		    scrollTrigger: {
		      trigger: ".js-trigger-top02", // アニメーションのトリガー要素
		      start: "top bottom", // アニメーション開始位置
		      end: "bottom top", // アニメーション終了位置
		      scrub: true, // 動作を遅くさせない
		    },
		  }
		);
	
	});

}

webfont();
gnavToggle();
// disableScroll();
inView();
splitText();
// // accordion();
slider();
gsapAnimation();

